@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    font-family: Outfit;
}

.custom__scroll::-webkit-scrollbar-thumb {
    background-color: rgba(102, 102, 102, 0.226);
    border: 4px solid rgba(0, 0, 0, 0);
    border-radius: 8px;
    background-clip: content-box;
}

.custom__scroll::-webkit-scrollbar {
    width: 12px;
}

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brand__box {
  background: #FFF;
  border-radius: 20px;
  padding: 0rem 7px;
  box-shadow: 0px 1px 3px rgb(3 0 71 / 9%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.brand__box:hover {
  box-shadow: 0px 8px 45px rgb(3 0 71 / 9%);
  margin-top: -5px;
}

.brand__load {
  padding: 0rem 1rem;
}

.brand__skeleton {
  overflow: hidden;
  border-radius: 20px;
}

.container-index {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 1.75rem;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 6rem;
}

.container-carousel {
  margin-top: 4rem;
}

@media (min-width: 1200px) {
  .container-index {
    max-width: 1280px !important;
  }
}

.box-filter {
  background-color: #fff;
  color: #2B3445;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgb(3 0 71 / 9%);
  overflow: hidden;
  border-radius: 8px;
  padding: 18px 6px 18px 0px;
  overflow: auto;
  width: 100%;

}

.form-check-input:focus {
  border: none !important;
  box-shadow: none;
}

.form-check-input:hover {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.card-container {
  padding-left: 24px;
  padding-bottom: 24px;
}

.line {
  border-color: #afb2b6;
  border-bottom-width: thin;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 27px;
}

.filter__title {
  padding-left: 27px;
}

.card {
  cursor: pointer;
  background-color: #fff;
  color: #2B3445;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgb(3 0 71 / 9%);
  overflow: hidden;
  border-radius: 8px;
  overflow: auto;
  border: none !important;
  min-height: 22rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.card:hover .hover-box {
  opacity: 1;
}

.view__more {
  opacity: 0;
}

.card:hover .view__more {
  opacity: 1;
}

.title {
  margin-bottom: 8px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #373F50;
  text-transform: uppercase;
}

.value {
  font-weight: 600;
  color: rgb(14 26 145);
}

.code {
  font-size: 14px;
  color: #7D879C;
  margin-bottom: .5rem;
}

.bg-footer {
  background-color: #222935;
}

.text-footer {
  color: #AEB4BE;
  text-transform: none;
  white-space: normal;
}

.hover-box {
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.footer-cards {
  line-height: 1.5;
  color: #7D879C;
  text-transform: none;
  white-space: normal;
  padding-left: 24px;
}

.filterName {
  font-size: 14px !important;
}

.filter__option::-webkit-scrollbar-thumb {
  background-color: rgba(102, 102, 102, 0.226);
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  background-clip: content-box;
}

.filter__option::-webkit-scrollbar {
  width: 12px;
}

.box-filter::-webkit-scrollbar {
  width: 13px;
}

.box-filter::-webkit-scrollbar-track {
  background: transparent;
}

.box-filter::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  background-clip: content-box;
}

.box-filter:hover::-webkit-scrollbar-thumb {
  background-color: rgba(102, 102, 102, 0.226);
}

.logo {
  background: url(assets/img/new_logo.png) no-repeat;
  background-position: 50%;
  background-size: contain;
  cursor: pointer;
  height: 70px;
  width: 215px;
}

.destaques {
  min-height: 50vh;
  display: flex;
  align-items: 'center';
}

.contact__header {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #FFF;
}

.contact__header span {
  cursor: pointer;
  text-decoration: underline;
  margin-left: 3px;
}

.destaque-img {
  background-position: 50%;
  background-size: contain;
  cursor: pointer;
  height: 278px;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: left;
  margin-top: -3px;
}

.text-link {
  color: #AEB4BE;
  cursor: pointer;
  transition: 0.5s;
}

.filter__option {
  max-height: 220px;
  position: relative;
  overflow: auto;
  margin: .5rem 0rem;
  padding-left: 27px;
}

.show__more {
  cursor: pointer;
  text-transform: lowercase;
  text-decoration: underline;
  color: #625959;
}

.text-link:hover {
  color: #FFF;
}

.card:hover {
  box-shadow: 0px 8px 45px rgb(3 0 71 / 9%);
  margin-top: -5px;
}

.default-filter {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 14px;
  color: #7D879C;
  text-transform: none;
  white-space: normal;
  font-family: 'Open Sans';
  font-weight: 500;
}

.overlay {
  background: rgb(17, 17, 17);
  opacity: 0.7;
  position: fixed;
  inset: 0px;
  z-index: 9000;
}

.box-filterMobile {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9001;
  left: 0;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px;
  background-color: #FFF;
}

.box-filterMobile-content {
  position: relative;
  top: 0;
  width: 100%;
  background-color: #fff;
  color: #2B3445;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  padding: 18px;
  overflow: auto;
}


.box-filterMobile-content .closebtn {
  position: absolute;
  top: -.5rem;
  right: 8px;
  font-size: 2rem;
  color: #FFF;
}

#myNav {
  display: none;
}

.search-input {
  width: 100%;
}

.form__equipment {
  width: 100%;
  margin: .5rem 0rem;
}

.close__modal {
  position: absolute;
  right: 5px;
  top: 5px;
}

.header-inputs {
  display: flex;
  justify-content: center;
}

.open__filterMobile {
  display: none;
}

.text-tab {
  font-size: .8rem;
  margin-top: -2px;
  margin-bottom: 5px;
}

.item__ava {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .1rem 0rem;
}

.title__equipment {
  margin-bottom: 16px;
  margin-top: 0px;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
}

.description__equipment {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 14px;
  color: rgb(75, 86, 107);
  text-transform: none;
  white-space: normal;
}

.tab__equipment {
  min-height: 40px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.details__equipment {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
}

.details__equipment span {
  display: flex;
  align-items: center;
}

.details__equipment span b {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
  margin-left: 5px;
}

.price__equipment {
  color: rgb(14 26 145);
  font-size: 25px;
  font-weight: 700;
  margin-bottom: .5rem !important;
}

.info__equipment {
  font-size: 14px;
  margin-right: 4px;
}

.star__equipment {
  margin-left: 4px;
}

.specification__title {
  margin-bottom: 16px;
  margin-top: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
}

.specifications {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.specifications .info {
  margin-bottom: 5px;
  font-size: .8rem;
}

.specifications .info b {
  font-size: .9rem;
}

.image__equipment {
  padding: .5rem 6rem 5rem 0rem;
}

.photo__equipment {
  min-height: 217px;
  background: #cccccc82;
  max-height: 217px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.tag__equipment {
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 600;
}

.offer_button {
  font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 0.875rem !important;
  background-color: rgb(14 26 145) !important;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px !important;
  font-weight: 600 !important;

}

.content-home {
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 24px;
  padding-right: 24px;
}

.cards-home {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}



.slick-list {
  padding: 1rem 0rem !important;
  margin-left: -23px;
}

.error_button {
  margin-top: 1.5rem !important;
  font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 0.875rem !important;
  background-color: #D23F57 !important;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px !important;
  font-weight: 600 !important;

}

.slick__navigationcustom {
  display: flex;
  background: rgb(73, 73, 73);
  align-items: center;
  justify-content: center;
  padding: 1.3rem;
  border-radius: 50%;
  font-size: 14px;
  z-index: 99;
}

.slick__navigationcustom:hover {
  background: rgb(101 101 101);
}



.slick-next:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  opacity: 1;
  font-size: 14px;
}

.slick-prev:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f053";
  opacity: 1;
  font-size: 14px;
}

.font-bold {
  font-weight: 700;
}

.description__slide {
  font-size: 14px;
  color: #0F3460;
}

.logout__button {
  position: absolute;
  right: 0;
}


.nodata-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity: .7;
  height: 80vh;
  align-content: center;
  color: #666;
}

.image__nodata {
  background: url(assets/img/no-data.png) no-repeat;
  background-size: contain;
  width: 100%;
  height: 320px;
  background-position: center center;
}

.water__mark {
  background: url(assets/img/NOVAMARCA.png) no-repeat;
  background-size: contain;
  width: 100%;
  height: 62%;
  position: absolute;
  left: 0%;
  top: 0%;
  opacity: .6;
}

.water__markEquipment {
  background: url(assets/img/NOVAMARCA.png) no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .6;
}

.terms__title {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 3.5rem;
  color: #000;
  letter-spacing: 2px;
  line-height: 4.5rem;
}

.terms__updated {
  text-align: center;
  margin-bottom: 3rem;
}

.container-terms {
  box-sizing: border-box;
  -webkit-box-flex-wrap: wrap;
  padding: 1.75rem;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 3rem;
}

.navbar-fixed {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.whatsapp__float {
  cursor: pointer;
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  background: #008614;
  color: #FFF;
  border-radius: 50%;
  padding: 0.5rem;
  z-index: 99;
  transition: all 500ms;
}

.whatsapp__float:hover {
  opacity: .8;
}

@media(max-width: 1024px) {
  .content-home {
    padding-left: 34px;
    padding-right: 34px;
  }

  .destaque-img {
    background-size: cover;
  }
}

@media(max-width: 600px) {
  .destaque-img {
    background-size: cover;
  }

  .container-carousel {
    margin-top: 2rem;
  }

  .image__equipment {
    padding: 0.5rem 0rem 2rem 0rem;
    margin-top: 2rem;
    width: 100%;
  }

  .details__equipment {
    margin-bottom: 2rem;
  }

  .wrap-mobile {
    flex-wrap: wrap !important;
  }

  .container-index {
    margin-top: 3rem;
  }

  .container-terms {
    margin-top: 3rem;
  }


  .card-container {
    padding-left: 0px !important;
    padding-top: 14px;
    padding-bottom: 10px !important;
    width: 100%;
  }

  .logo {
    width: 50px;
    height: 50px;
    background-size: contain;
    background: url(./assets/img/favi.png) no-repeat;
    background-size: contain;
  }

  #myNav {
    display: block;
  }

  .container-header {
    flex-wrap: nowrap !important;
  }

  .search-input {
    width: 70%;
  }

  .header-inputs {
    display: flex;
    justify-content: center;
  }

  .open__filterMobile {
    display: block;
  }

  .box-filter {
    display: none;
  }

  .contact__header {
    display: none;
  }
}*/